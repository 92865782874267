<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Notice Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Title" 
          :danger="validation.title?true:false"
          :danger-text="validation.title?validation.title[0]:''"
          :value="form.title"
          @input="updateForm({key: 'title', value: $event})" />
      </div>
      <div class="vx-col w-full">
        <vs-textarea
          class="w-full mt-4"
          label="Description"
          :value="form.description"
          @input="updateForm({ key: 'description', value: $event })" />
      </div>

    <div class="vx-col w-full md:w-3/3">
      <label class=" vs-input--label">Type</label>
        <v-select 
          :danger="validation.type?true:false"
          :value="dropdown.type"
          @input="updateForm({key: 'type', value: $event})"
          :options="types" />
          <span class="text-danger text-sm" 
            v-show="validation.type?true:false">
        {{ validation.type?validation.type[0]:''}}
      </span>
    </div>
    </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'

export default {  
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        form: 'notice/getFormObj',
        validation: 'validation/getValidationErrors',
        types: 'notice/getNoticeTypes',
        dropdown: 'notice/getDropdownObj',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'notice/updateForm',
    })
  }
}
</script>
